import React, { useEffect, useState } from "react";

import { GetPubAbbr } from "../../helpers/get-pub-abbr";
import Header from "../../layouts/Header";
import { Breadcrumbs } from "../inventory/components/Breadcrumbs";
import { AddItemButton } from "../inventory/components/AddItemButton";
import BasicTable from "../../components/BasicTable";
import Footer from "../../layouts/Footer";
import { switchSkin } from "../../helpers/switch-skin";
import {
  CookiesKeyNames,
  useCookiesContext,
} from "../../contexts/CookiesContext";
import getClientApplications from "../../services/micro/get_client_applications";
const colWidth = {
  first_name: 150,
  last_name: 100,
  email: 150,
  company_name: 150,
  product_name: 150,
  main_category: 150,
  client_application_status: 150,
};

const rowFields = [
  "id",
  "first_name",
  "last_name",
  "email",
  "company_name",
  "product_name",
  "main_category",
  "client_application_status",
];

export default function PublisherApplications() {
  const publisherIdByAbbr = GetPubAbbr();

  const title = "Applications";
  const breadcrumb = "Customers";
  const new_btn_title = "Onboard App";
  const base_url = "/pub/customers/applications";
  const new_btn_redirect = base_url + "/new";
  const edit_redirect = base_url + "/edit";
  const breadcrumb_redirect = base_url + "/applications";

  const { cookiesData, getPathKey } = useCookiesContext();

  const currentSkin =
    cookiesData[getPathKey()]?.[CookiesKeyNames.skinMode] === "dark"
      ? "dark"
      : "";
  const [skin, setSkin] = useState(currentSkin);

  const additionalInfo = {
    publisherIdByAbbr: publisherIdByAbbr,
    currentSkin: currentSkin,
  };

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app d-flex flex-column p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <Breadcrumbs
            breadcrumb={breadcrumb}
            breadcrumb_redirect={breadcrumb_redirect}
            title={title}
          />
          <AddItemButton
            new_btn_title={new_btn_title}
            new_btn_redirect={new_btn_redirect}
          />
        </div>

        <BasicTable
          getData={getClientApplications}
          url={edit_redirect}
          colWidth={colWidth}
          rowFields={rowFields}
          additionalInfo={additionalInfo}
          skin={skin}
        />

        <Footer />
      </div>
    </React.Fragment>
  );
}
