import axiosInstance from "../../axios/instance";
import { getToken } from "../../helpers/get-token";

async function createNewApplication(values) {
  try {
    const token = getToken();
    const publisher_id = window.location.search.split("=")[1];

    const response = await axiosInstance.post(
      "/api/client-applications/",
      { ...values, publisher_id },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    console.log("response", response);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export default createNewApplication;
