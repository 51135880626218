/**
 * The function checks that the domain from the email matches the domain from the URL.
 *
 * @param {string} email - Email address.
 * @param {string} url - URL address.
 * @returns {Object} - An object of type { valid: boolean, error?: string }.
 */

export function validateEmailAndUrl(email, url) {
  if (!email || !url) {
    return {
      valid: false,
      error: "Please enter your email and URL.",
    };
  }

  const emailParts = email.trim().split("@");
  if (emailParts.length !== 2) {
    return {
      valid: false,
      error: "Incorrect email format: must contain exactly one '@' character.",
    };
  }

  let emailDomain = emailParts[1].trim().toLowerCase();

  emailDomain = emailDomain.replace(/^\.+|\.+$/g, "");

  let parsedUrl;
  try {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      url = "http://" + url;
    }
    parsedUrl = new URL(url.trim());
  } catch (error) {
    console.log(error);
    return {
      valid: false,
      error: "Incorrect email format",
    };
  }

  let urlHostname = parsedUrl.hostname.toLowerCase();

  if (urlHostname.startsWith("www.")) {
    urlHostname = urlHostname.substring(4);
  }
  urlHostname = urlHostname.replace(/^\.+|\.+$/g, "");

  if (emailDomain === urlHostname) {
    return { valid: true };
  } else {
    return {
      valid: false,
      error: `Domain with email ("${emailDomain}") does not match the domain with the URL ("${urlHostname}").`,
    };
  }
}
