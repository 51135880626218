import { GetPubAbbr } from "../../../../../helpers/get-pub-abbr";
import { TaxonomyModule } from "../../../../../modules/taxonomy";

export function TopBidCellColumnHeaderTooltipRenderer() {
    const publisherIdByAbbr = GetPubAbbr();
    const taxonomy = TaxonomyModule(publisherIdByAbbr);

    return taxonomy.tooltips.ppc_cluster_column_headers.top_bid;
}

export function TopBidCellTooltipRenderer({ value }) {
    const publisherIdByAbbr = GetPubAbbr();
    const taxonomy = TaxonomyModule(publisherIdByAbbr);

    return taxonomy.tooltips.ppc_cluster_column_inlines.top_bid;
}
 