import axios from "axios";
import { delayFunc } from "./delay";
import { toast } from "react-toastify";
import { validateEmailAndUrl } from "./validate-email-and-url";

const steps = [
  { message: "Creating the new publisher...", delay: 1000 },
  { message: "Creating the system tables...", delay: 2000 },
  { message: "Creating the info columns...", delay: 2000 },
  { message: "Preparing...", delay: 1500 },
  { message: "Redirecting...", delay: 2500 },
];

const labels = {
  first_name: "first name",
  last_name: "last name",
  email: "email",
  company_name: "company name",
  product_name: "product name",
  product_url: "product URL",
  main_category: "category",
};

export const handleFormSubmit = async ({
  e,
  formData,
  setIsLoading,
  token,
}) => {
  e.preventDefault();

  for (const [key, value] of Object.entries(formData)) {
    if (!value) {
      toast.error(`Please enter your ${labels[key]}.`);
      return;
    }
  }

  const result = validateEmailAndUrl(formData.email, formData.product_url);

  if (!result.valid) {
    toast.error(result.error);
    return;
  }

  setIsLoading(true);
  toast.success("Registration started");

  for (const { delay, message } of steps) {
    await delayFunc(delay);
    toast.success(message);
  }

  const submit = await postData(formData, token);

  if (submit?.success) {
    toast.success("Your company has been successfully registered.");
  }

  setIsLoading(false);
};

const postData = async (data, token) => {
  axios.defaults.headers.common["authorization"] = token;
  const response = await axios.post(
    process.env.REACT_APP_HUB_URL + "/api/client-applications/",
    data
  );

  return response.data;
};
