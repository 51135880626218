import React, { createContext, useState, useContext } from "react";
import Cookies from "universal-cookie";
import { decrypt } from "../helpers/crypto";

export const CookiesKeyNames = {
  advertiserId: "advertiser_id",
  abbreviation: "abbreviation",
  clickedDeactivate: "clickedDeactivate",
  clickedActivate: "clickedActivate",
  currentPeriod: "currentPeriod",
  previousPeriod: "previousPeriod",
  publisherId: "publisher_id",
  publisherToken: "publisher_token",
  sidebarSkin: "sidebar_skin",
  skinMode: "skin-mode",
  token: "token",
};

export const CookiesContext = createContext();

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get("token");
const isIframe = token ? true : false;
const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
const isSafari =
  isIOS &&
  /Safari/i.test(navigator.userAgent) &&
  !/Chrome/i.test(navigator.userAgent);

export const isCookiesEnabled = isSafari
  ? true
  : isIframe
  ? false
  : navigator.cookieEnabled;

const cookies = new Cookies();

export const getPathKey = () => {
  if (typeof window === "undefined") return "pub";

  const { pathname = "", search } = window.location;
  let advId = null;

  if (search) {
    const params = new URLSearchParams(search);
    let encryptedAdvId = params.get("advId");
    if (encryptedAdvId) {
      encryptedAdvId = encryptedAdvId.replace(/\+/g, "%20"); // Replaces plus with space
      encryptedAdvId = decodeURIComponent(encryptedAdvId); // Decode again
      advId = decrypt(encryptedAdvId);
    }
  }

  const pathKey = pathname.includes("/adv") ? advId : "pub";
  return pathKey;
};

export const CookiesProvider = ({ children }) => {
  const initializeState = () => {
    const keys = Object.values(CookiesKeyNames);
    const finalData = {};
    const storage = isCookiesEnabled ? cookies : localStorage;

    // get all sessions from storage (pub, advertiserId)
    const allKeys = isCookiesEnabled
      ? document.cookie.split("; ").map((cookie) => cookie.split("=")[0])
      : Object.keys(localStorage);

    // filter keys by pattern "pub" or advertiserId
    const filteredKeys = allKeys.filter((key) => /^pub$|^\d+$/.test(key));

    filteredKeys.forEach((key) => {
      let storedData = storage?.get ? storage.get(key) : storage.getItem(key);
      finalData[key] = isCookiesEnabled ? storedData : JSON.parse(storedData);
    });

    const pathKey = getPathKey();

    let storedDataForPath = storage?.get
      ? storage.get(pathKey)
      : storage.getItem(pathKey);

    if (storedDataForPath) {
      finalData[pathKey] = isCookiesEnabled
        ? storedDataForPath
        : JSON.parse(storedDataForPath);
    } else {
      const tabData = {};
      keys.forEach((key) => {
        tabData[key] =
          (storage?.get
            ? storage.get(pathKey)?.[key]
            : JSON.parse(storage.getItem(pathKey))?.[key]) || null;
      });

      finalData[pathKey] = tabData;

      const dataToStore = isCookiesEnabled ? tabData : JSON.stringify(tabData);
      storage?.set
        ? storage.set(pathKey, dataToStore, { path: "/" })
        : storage.setItem(pathKey, dataToStore);
    }

    return finalData;
  };

  const [cookiesData, setCookiesData] = useState(initializeState);

  const updateCookiesProperty = (key, value, advId = "") => {
    const pathKey = getPathKey();
    const mainKey = advId ? advId : pathKey;

    if (!key || !value) return;
    setCookiesData((prevData) => {
      const newData = {
        ...prevData,
        [mainKey]: {
          ...prevData[mainKey],
          [key]: value,
        },
      };

      const dataToStore = JSON.stringify(newData[mainKey]);
      if (isCookiesEnabled) {
        cookies.set(mainKey, dataToStore, { path: "/" });
      } else {
        localStorage.setItem(mainKey, dataToStore);
      }

      return newData;
    });
  };

  const removeCookiesProperty = (key, advId = "") => {
    const pathKey = getPathKey();
    const mainKey = advId ? advId : pathKey;
    setCookiesData((prevData) => {
      const { [key]: _, ...rest } = prevData[mainKey];

      const newData = {
        ...prevData,
        [mainKey]: rest,
      };

      const dataToStore = JSON.stringify(rest);
      if (isCookiesEnabled) {
        cookies.set(mainKey, dataToStore, { path: "/" });
      } else {
        localStorage.setItem(mainKey, dataToStore);
      }

      return newData;
    });
  };

  const clearCookies = () => setCookiesData({});

  return (
    <CookiesContext.Provider
      value={{
        cookiesData,
        getPathKey,
        updateCookiesProperty,
        removeCookiesProperty,
        clearCookies,
      }}
    >
      {children}
    </CookiesContext.Provider>
  );
};

export const useCookiesContext = () => useContext(CookiesContext);
