import { GetPubAbbr } from "../../../../../helpers/get-pub-abbr";
import { TaxonomyModule } from "../../../../../modules/taxonomy";

export function MissedClicksForecastRenderer({ value }) {
    if (!value) {
        return null;
    }

    const est_clicks = Number(value.split("@")[0]);
    const est_missed_clicks = Number(value.split("@")[1]);

    const est_click_opportunity = Number(value.split("@")[2]);
    const items_amount = Number(value.split("@")[3]);
    const min_increment = Number(value.split("@")[4]);
    const top_bid = Number(value.split("@")[5]);
    const cpc = Number(value.split("@")[6]);
    const status = value.split("@")[7];
    const position = Number(value.split("@")[8]);

    let percentage = (est_clicks / (est_clicks + est_missed_clicks)) * 100;
    
    // if (missed_clicks_position_one > missed_clicks) {
    //     diff = 
    //     percentage = (missed_clicks / (missed_clicks_position_one + missed_clicks)) * 100;
    // } else {
    //     diff = missed_clicks;
    //     percentage = 5;
    // }

    if (status?.toUpperCase() === "INACTIVE") {
        percentage = 5;
    }

    if (percentage < 5) {
        percentage = 5;
    }

    const clicks = est_missed_clicks === 1 ? 'click' : 'clicks';

    // if (missed_clicks === 0 && status?.toUpperCase() !== "INACTIVE") {
    //     return (
    //         <div className="d-flex align-items-center">
    //             <div className="progress" style={{height: '14px', width: '80px'}}>
    //                 <div role="progressbar" className="progress-bar bg-primary" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width: "100%"}}>
    //                 </div>
    //             </div>
    //             <div style={{width: '70px', marginLeft: '10px'}}>
    //                 Optimized
    //             </div>
    //         </div>
    //     );
    // }

    if (position == 1 && status?.toUpperCase() !== "INACTIVE") {
        return (
            <div className="d-flex align-items-center">
                <div className="progress" style={{height: '14px', width: '80px'}}>
                    <div role="progressbar" className="progress-bar bg-primary" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width: "100%"}}>
                    </div>
                </div>
                <div style={{width: '70px', marginLeft: '10px'}}>
                    Optimized
                </div>
            </div>
        );
    }

    if (status?.toUpperCase() === "INACTIVE") {
        return (
            <div className="d-flex align-items-center">
                <div className="progress" style={{height: '14px', width: '80px'}}>
                    <div role="progressbar" className="progress-bar bg-danger" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width: "5%"}}>
                    </div>
                </div>
                <div style={{width: '70px', marginLeft: '10px'}}>
                    {est_missed_clicks} {clicks} <i className="ri-information-line text-danger"></i>
                </div>
            </div>
        );
    }

    if (status?.toUpperCase() === "DRAFT" || status?.toUpperCase() === "PENDING") {
        return (
            <div className="d-flex align-items-center">
                <div className="progress" style={{height: '14px', width: '80px'}}>
                    <div role="progressbar" className="progress-bar bg-danger" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width: "5%"}}>
                    </div>
                </div>
                <div style={{width: '70px', marginLeft: '10px'}}>
                    {est_missed_clicks} {clicks} <i className="ri-information-line text-danger"></i>
                </div>
            </div>
        );
    }

    if (est_missed_clicks <= 0) {
        return (
            <div className="d-flex align-items-center">
                <div className="progress" style={{height: '14px', width: '80px'}}>
                    <div role="progressbar" className="progress-bar bg-primary" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width: "100%"}}>
                    </div>
                </div>
                <div style={{width: '70px', marginLeft: '10px'}}>
                    Optimized
                </div>
            </div>
        );
        // return (
        //     <div className="d-flex align-items-center">
        //         <div className="progress" style={{height: '14px', width: '80px'}}>
        //             <div role="progressbar" className="progress-bar bg-danger" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width: percentage + "%"}}>
        //             </div>
        //         </div>
        //         <div style={{width: '70px', marginLeft: '10px'}}>
        //             {est_missed_clicks} {clicks} <i className="ri-information-line text-secondary"></i>
        //         </div>
        //     </div>
        // );
    } else if (est_missed_clicks > 0 && percentage > 30) {
        return (
            <div className="d-flex align-items-center">
                <div className="progress" style={{height: '14px', width: '80px'}}>
                    <div role="progressbar" className="progress-bar bg-primary" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width: percentage + "%"}}>
                    </div>
                </div>
                <div style={{width: '70px', marginLeft: '10px'}}>
                    {est_missed_clicks} {clicks} <i className="ri-information-line text-secondary"></i>
                </div>
            </div>
        );
    } else {
        return (
            <div className="d-flex align-items-center">
                <div className="progress" style={{height: '14px', width: '80px'}}>
                    <div role="progressbar" className="progress-bar bg-danger" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width: percentage + "%"}}>
                    </div>
                </div>
                <div style={{width: '70px', marginLeft: '10px'}}>
                    {est_missed_clicks} {clicks} <i className="ri-information-line text-danger"></i>
                </div>
            </div>
        );
    }
}
 
export function MissedClicksForecastCellColumnHeaderTooltipRenderer() {
    const publisherIdByAbbr = GetPubAbbr();
    const taxonomy = TaxonomyModule(publisherIdByAbbr);

    return taxonomy.tooltips.ppc_cluster_column_headers.missed_clicks_forecast;
}

export function MissedClicksForecastCellTooltipRenderer({ value }) {
    const publisherIdByAbbr = GetPubAbbr();
    const taxonomy = TaxonomyModule(publisherIdByAbbr);

    if (!value) {
        return null;
    }
    // Your current position received 596 fewer clicks over the last 30 days than the top position.

    const est_clicks = Number(value.split("@")[0]);
    const est_missed_clicks = Number(value.split("@")[1]);

    const est_click_opportunity = Number(value.split("@")[2]);
    const items_amount = Number(value.split("@")[3]);
    const min_increment = Number(value.split("@")[4]);
    const top_bid = Number(value.split("@")[5]);
    const cpc = Number(value.split("@")[6]);
    const status = value.split("@")[7];
    const position = Number(value.split("@")[8]);

    if (status?.toUpperCase() === "PAUSED") {
        return taxonomy.tooltips.ppc_cluster_column_inlines.missed_clicks_forecast.bid_is_paused;
    }

    if (status?.toUpperCase() === "INACTIVE") {
        const incerement = top_bid + min_increment;

        if (est_missed_clicks === 0) {
            return ``;
        }

        return taxonomy.tooltips.ppc_cluster_column_inlines.missed_clicks_forecast.no_bid.replace("{missed_clicks}", est_missed_clicks).replace("{top_bid_plus_one}", incerement);
        // return `Bid $${incerement} to earn the #1 position and generate ~${est_missed_clicks} clicks over the next 30 days`;
    }

    if (position === 1) {
        return taxonomy.tooltips.ppc_cluster_column_inlines.missed_clicks_forecast.optimized.replace("{clicks}", est_missed_clicks);
        // return `You are in the top position winning the most clicks in the inventory. Monthly clicks forecast = ${est_missed_clicks} clicks/mo`;
    }

    else if (position <= items_amount) {
        const incerement = (top_bid - cpc) + min_increment;

        if (incerement > 0) {
            return taxonomy.tooltips.ppc_cluster_column_inlines.missed_clicks_forecast.active_bid_but_not_optimized.replace("{clicks}", est_missed_clicks).replace("{increase_by}", incerement).replace("{relative_opportunity}", est_click_opportunity);
            // return `Your current position received ${est_missed_clicks} fewer clicks over the last 30 days than the top position. Increase your bid by $${incerement} to receive ${est_click_opportunity}x the click volume.`;
        } else {
            return taxonomy.tooltips.ppc_cluster_column_inlines.missed_clicks_forecast.optimized.replace("{clicks}", est_missed_clicks);
            // return `You are in the top position winning the most clicks in the inventory. Monthly clicks forecast = ${est_missed_clicks} clicks/mo`;
        }
    }

    else {
        const incerement = top_bid + min_increment;

        if (est_missed_clicks === 0) {
            return ``;
        }

        return taxonomy.tooltips.ppc_cluster_column_inlines.missed_clicks_forecast.no_bid.replace("{missed_clicks}", est_missed_clicks).replace("{top_bid_plus_one}", incerement);
        // return `Bid $${incerement} to earn the #1 position and generate ~${est_missed_clicks} clicks over the next 30 days`;
    }
}