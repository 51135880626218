import axiosInstance from "../../axios/instance";
import { getToken } from "../../helpers/get-token";

/**
 * Retrieves client applications based on the provided parameters.
 *
 * @param {number} application_id - Ідентифікатор заявки.
 * @returns {Promise<Object>} - Об'єкт з даними заявки.
 * @throws {Error} - Якщо виникає помилка під час запиту або відсутній токен авторизації.
 */
async function getClientApplicationById(application_id) {
  try {
    const token = getToken();
    if (!token) {
      throw new Error("Token not found. Authorization cannot be performed.");
    }
    axiosInstance.defaults.headers.common["Authorization"] = token;

    const response = await axiosInstance.get(
      `/api/client-applications/${application_id}`
    );
    // console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error fetching client applications data:", error);
    throw error;
  }
}

export default getClientApplicationById;
