import { Form, Card, Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { CookiesKeyNames, useCookiesContext } from "../contexts/CookiesContext";
import { GetPubAbbr } from "../helpers/get-pub-abbr";
import { handleFormSubmit } from "../helpers/handle-signup-submit";
import { logosModule } from "../modules/logos";
import { useCallback } from "react";
import { getPublisherCategories } from "../helpers/get-publisher-categories";

const selects = [
  { label: "Option 1", value: 1 },
  { label: "Option 2", value: 2 },
  { label: "Option 3", value: 3 },
  { label: "Option 4", value: 4 },
];

export const Onboarding = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [productName, setProductName] = useState("");
  const [productUrl, setProductUrl] = useState("");
  const [category, setCategory] = useState(null);
  const [categories, setCategories] = useState([]);

  const { cookiesData, getPathKey } = useCookiesContext();

  const cookiesDataByPath = cookiesData[getPathKey()];
  const publisherId = useMemo(
    () => cookiesDataByPath?.[CookiesKeyNames.publisherId] || GetPubAbbr(),
    [cookiesDataByPath]
  );

  const currentSkin = cookiesDataByPath?.[CookiesKeyNames.skinMode];
  const token = cookiesDataByPath?.[CookiesKeyNames.token];
  const siteLogo = useMemo(
    () => logosModule(publisherId, currentSkin),
    [publisherId, currentSkin]
  );

  useEffect(() => {
    const fetchCategories = async () => {
      const result = await getPublisherCategories(token, publisherId);

      if (result) {
        const formatedCategories = result.data.map((category) => ({
          label: category.name,
          value: category.id,
        }));
        setCategories(formatedCategories);
      }
    };

    fetchCategories();
  }, [publisherId, token]);

  const onSubmit = useCallback(
    (e) => {
      const formData = {
        first_name: firstName,
        last_name: lastName,
        email,
        company_name: companyName,
        product_name: productName,
        product_url: productUrl,
        main_category: category?.value,
      };
      handleFormSubmit({
        e,
        formData,
        setIsLoading,
        token,
      });
    },
    [
      firstName,
      lastName,
      email,
      companyName,
      productName,
      productUrl,
      category,
      setIsLoading,
      token,
    ]
  );

  return (
    <Form className="page-sign" onSubmit={onSubmit}>
      <Card className="card-sign" style={{ width: "620px" }}>
        <Card.Header>
          <Link to="/" className="header-logo">
            {siteLogo}
          </Link>
          <Card.Title className="mb-4">Advertiser Sign Up</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row className="g-3">
            <Col md={6} className="p-1">
              <Form.Label>First name</Form.Label>
              <Form.Control
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                value={firstName}
                type="text"
                placeholder="Enter your first name*"
              />
            </Col>
            <Col md={6} className="p-1">
              <Form.Label>Last name</Form.Label>
              <Form.Control
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                value={lastName}
                type="text"
                placeholder="Enter your last name*"
              />
            </Col>
            <Col md={6} className="p-1">
              <Form.Label>Email</Form.Label>
              <Form.Control
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
                type="email"
                placeholder="Enter your email address*"
              />
            </Col>
            <Col md={6} className="p-1">
              <div className="col">
                <small
                  className="mt-4 onboarding-border"
                  style={{
                    borderLeft: "solid 3px",
                    padding: "5px 7px",
                  }}
                >
                  Please use your company email, so we can verify that your
                  company owns the product.
                </small>
              </div>
            </Col>
            <hr style={{ marginBottom: 0 }} />
            <Col md={12} className="p-1" style={{ marginTop: "0.25rem" }}>
              <Form.Label>Company name</Form.Label>
              <Form.Control
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
                value={companyName}
                type="text"
                placeholder="Enter your company name*"
              />
            </Col>
            <hr style={{ marginBottom: 0 }} />
            <Col md={6} className="p-1" style={{ marginTop: "0.25rem" }}>
              <Form.Label>Product name</Form.Label>
              <Form.Control
                onChange={(e) => {
                  setProductName(e.target.value);
                }}
                value={productName}
                type="text"
                placeholder="Enter your product name*"
              />
            </Col>
            <Col md={6} className="p-1" style={{ marginTop: "0.25rem" }}>
              <Form.Label>Product url</Form.Label>
              <Form.Control
                onChange={(e) => {
                  setProductUrl(e.target.value);
                }}
                value={productUrl}
                type="text"
                placeholder="Enter your product url*"
              />
            </Col>
            <Col md={6} className="p-1" style={{ marginTop: "0.25rem" }}>
              <Form.Label>Main category</Form.Label>

              <Select
                className="custom-select"
                id="basic-select-single"
                value={category}
                placeholder={"Pick your category*"}
                options={categories}
                onChange={(selectedOption) => setCategory(selectedOption)}
              />
            </Col>
            <Col md={6} className="p-1" style={{ marginTop: "0.25rem" }}>
              <div className="col">
                <small
                  className="mt-4 onboarding-border"
                  style={{
                    borderLeft: "solid 3px",
                    padding: "5px 7px",
                  }}
                >
                  Pick the category that best describes your product. You can
                  add more later.
                </small>
              </div>
            </Col>
          </Row>
          <div className="mt-2 mb-3">
            <small>
              By applying for access below, you agree to our{" "}
              <a href="/" target="_blank">
                terms of service
              </a>{" "}
              and{" "}
              <a href="/" target="_blank">
                privacy policy
              </a>
              . The application review is AI powered - you will get an immediate
              answer if your product is elegible.
            </small>
          </div>
          <hr />
          <Button type="submit" variant="primary" className="btn-sign">
            Apply for access
          </Button>
        </Card.Body>
        <Card.Footer style={{ padding: "35px" }}>
          Already have an account? <a href="/pages/signin">Sign In</a> instead.
        </Card.Footer>
      </Card>
    </Form>
  );
};
