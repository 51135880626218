import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../../../../../scss/components/_ag-table.scss";
import { getServerSideDatasourcePPC } from "../../../../../helpers/get-serverside-datasource-ppc";
import "ag-grid-charts-enterprise";
import {
  PositionRenderer,
  PositionCellTooltipRenderer,
  PositionColumnHeaderTooltipRenderer,
} from "./BrandingPositionRenderer";
import { StatusCellTooltipRenderer, StatusRenderer, StatusColumnHeaderTooltipRenderer } from "./BrandingStatusRenderer";
import { NameRenderer, NameCellTooltipRenderer } from "../specific/SpecificNameRenderer";
import {
  YourBidCellColumnHeaderTooltipRenderer,
  YourBidCellRenderer,
  YourBidCellTooltipRenderer,
} from "./BrandingYourBidRenderer";
import { CustomTooltip } from "../../CustomTooltip";
import { BrandingNameCellTooltipRenderer, BrandingNameColumnHeaderTooltipRenderer, BrandingNameRenderer } from "./BrandingNameRenderer";
import { TopBidCellColumnHeaderTooltipRenderer, TopBidCellTooltipRenderer } from "./BrandingTopBidRenderer";
import { YourClicksCellColumnHeaderTooltipRenderer, YourClicksCellTooltipRenderer } from "./BrandingClicksRenderer";
import { ActionCellColumnHeaderTooltipRenderer, ActionCellTooltipRenderer, ActionRenderer } from "./BrandingActionRenderer";
import { MissedClicksForecastCellColumnHeaderTooltipRenderer, MissedClicksForecastCellTooltipRenderer, MissedClicksForecastRenderer } from "./BrandingMissedClicksForecastRenderer";

const customColumns = [
  {
    field: "Name",
    cellRenderer: BrandingNameRenderer,
    headerTooltip: BrandingNameColumnHeaderTooltipRenderer(),
    tooltipValueGetter: BrandingNameCellTooltipRenderer
  },
  {
    field: "Position",
    cellRenderer: PositionRenderer,
    headerTooltip: PositionColumnHeaderTooltipRenderer(),
    tooltipValueGetter: PositionCellTooltipRenderer,
  },
  {
    field: "Status",
    cellRenderer: StatusRenderer,
    headerTooltip: StatusColumnHeaderTooltipRenderer(),
    tooltipValueGetter: StatusCellTooltipRenderer,
  },
  {
    field: "Your Bid",
    cellRenderer: YourBidCellRenderer,
    headerTooltip: YourBidCellColumnHeaderTooltipRenderer(),
    tooltipValueGetter: YourBidCellTooltipRenderer
  },
  {
    field: "Top Bid",
    headerTooltip: TopBidCellColumnHeaderTooltipRenderer(),
    tooltipValueGetter: TopBidCellTooltipRenderer
  },
  {
    field: "Your Clicks",
    headerTooltip: YourClicksCellColumnHeaderTooltipRenderer(),
    tooltipValueGetter: YourClicksCellTooltipRenderer,
  },
  {
    field: "Action",
    cellRenderer: ActionRenderer,
    headerTooltip: ActionCellColumnHeaderTooltipRenderer(),
    tooltipValueGetter: ActionCellTooltipRenderer
  },
  {
    field: "Missed Clicks Forecast",
    cellRenderer: MissedClicksForecastRenderer,
    headerTooltip: MissedClicksForecastCellColumnHeaderTooltipRenderer(),
    tooltipValueGetter: MissedClicksForecastCellTooltipRenderer,
  },
];

export const BrandingPPCAuctions = ({
  getData,
  skin,
  onClick,
  campaignId,
  updatePage,
  isHTR,
  setBrandingActiveAuctions
}) => {
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ minHeight: "200px", width: "100%" }), []);

  const [search, setSearch] = useState("");
  const [gridApi, setGridApi] = useState(null);
  const [columnDefs, setColumnDefs] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData({}, campaignId).then((res) => {
      setData(res.data);

      if (res.data.rows.length > 0) {
        const activeAuctions = res.data.rows.filter(item => item[2] === 'Live');
        setBrandingActiveAuctions(activeAuctions);
      }
    });
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 90,
      tooltipComponent: CustomTooltip,
    };
  }, []);

  const onFilterTextBoxChanged = (e) => {
    setSearch(e.target.value);
  };

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    const datasource = getServerSideDatasourcePPC(
      getData,
      setColumnDefs,
      search,
      customColumns,
      campaignId
    );

    params.api.setGridOption("serverSideDatasource", datasource);
  }, []);

  useEffect(() => {
    if (gridApi) {
      const datasource = getServerSideDatasourcePPC(
        getData,
        setColumnDefs,
        search,
        customColumns,
        campaignId
      );

      gridApi.setGridOption("serverSideDatasource", datasource);
    }
  }, [gridApi, search, updatePage, campaignId]);

  if (data?.rows?.length === 0) {
    return <></>;
  }

  return (
    <Card className="card-one">
      <Card.Header className="d-flex flex-column align-items-start">
        <Card.Title as="h6" className="mb-2">
          Branding Auctions
        </Card.Title>
        { isHTR &&
          <Card.Subtitle as="p">
            Best for building brand awareness with more impressions in high traffic areas.
            <Card.Link
              target="_blank"
              className="ps-2"
              href="https://help.hoteltechreport.com/en/articles/10084263-understanding-the-types-of-auctions"
            >
              Learn more
            </Card.Link>
          </Card.Subtitle>
        }
      </Card.Header>
      <Card.Body>
        <div style={containerStyle}>
          <div
            style={gridStyle}
            className={
              skin === "dark" ? "ag-theme-quartz-dark ag-theme-quartz" : "ag-theme-quartz-light ag-theme-quartz"
            }
          >
            <div style={{ marginBottom: "10px" }}>
              <input
                type="text"
                className="input-search"
                placeholder="Search..."
                onInput={onFilterTextBoxChanged}
              />
            </div>
            <AgGridReact
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              pagination={true}
              tooltipShowDelay={500}
              tooltipHideDelay={200000}
              paginationPageSize={10}
              rowHeight={55}
              paginationPageSizeSelector={false}
              cacheBlockSize={10}
              rowModelType={"serverSide"}
              domLayout={"autoHeight"}
              suppressContextMenu={true}
              onCellClicked={onClick}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
