import React, { useEffect, useState } from "react";
import { Col, Button, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { startOfDay, endOfDay, addDays, subDays } from "date-fns";

import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import RevenueChart from "./analytics/revenue/RevenueChart";
import AverageCPC from "./analytics/revenue/AverageCPC";
import AverageCTR from "./analytics/revenue/AverageCTR";
import Totals from "./analytics/revenue/Totals";
import TopCampaigns from "./analytics/revenue/TopCampaigns";
import TopRevenue from "./analytics/revenue/TopRevenue";
import DatePicker from "./components/DatePicker";
import { isIOSOrSafari } from "../helpers/is-ios-or-safari";
import {
  CookiesKeyNames,
  isCookiesEnabled,
  useCookiesContext,
} from "../contexts/CookiesContext";

const Ranges = [
  {
    label: "today",
    value: [startOfDay(new Date()), endOfDay(new Date())],
  },
  {
    label: "yesterday",
    value: [
      startOfDay(addDays(new Date(), -1)),
      endOfDay(addDays(new Date(), -1)),
    ],
  },
  {
    label: 'Last 30 Days',
    value: [startOfDay(subDays(new Date(), 30)), endOfDay(new Date())]
  }
];

export default function PublisherRevenueMonitoring() {
  const navigate = useNavigate();
  const { cookiesData, updateCookiesProperty, getPathKey } =
    useCookiesContext();

  const urlParams = new URLSearchParams(window.location.search);
  const refresh = urlParams.get("refresh");
  const token = cookiesData[getPathKey()]?.[CookiesKeyNames.token];

  useEffect(() => {
    const { currentPeriod, previousPeriod } = loadDateRange();
    setCurrentPeriod(currentPeriod);
    setPreviousPeriod(previousPeriod);
  }, []);

  if (refresh) {
    const navigateLink = isIOSOrSafari()
      ? "/pub/dashboard/revenue?token=" + token
      : "/pub/dashboard/revenue";

    // navigate(navigateLink);
    // window.location.reload();

    // reload and clean url params
    window.location.href = navigateLink;
  }

  // Function to load initial state from cookies or default values
  const loadDateRange = () => {
    const storedCurrentPeriod = isCookiesEnabled
      ? cookiesData[getPathKey()]?.[CookiesKeyNames.currentPeriod]
      : JSON.parse(cookiesData[getPathKey()]?.[CookiesKeyNames.currentPeriod]);
    const storedPreviousPeriod = isCookiesEnabled
      ? cookiesData[getPathKey()]?.[CookiesKeyNames.previousPeriod]
      : JSON.parse(cookiesData[getPathKey()]?.[CookiesKeyNames.previousPeriod]);

    // Parse dates from cookies, if available
    if (storedCurrentPeriod && storedPreviousPeriod) {
      return {
        currentPeriod: storedCurrentPeriod.map((date) => new Date(date)),
        previousPeriod: storedPreviousPeriod.map((date) => new Date(date)),
      };
    }
    
    const begin_of_day = startOfDay(new Date());
    const end_of_day = endOfDay(new Date());

    // Fallback to the last 30 days if nothing is stored in cookies
    const defaultCurrentPeriod = [
      subDays(begin_of_day, 30),
      new Date(end_of_day),
    ];
    const defaultPreviousPeriod = [
      subDays(begin_of_day, 60),
      subDays(end_of_day, 30),
    ];
    return {
      currentPeriod: defaultCurrentPeriod,
      previousPeriod: defaultPreviousPeriod,
    };
  };

  const [currentPeriod, setCurrentPeriod] = useState([null, null]);
  const [previousPeriod, setPreviousPeriod] = useState([null, null]);
  const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);

  useEffect(() => {
    if (
      currentPeriod &&
      currentPeriod.length === 2 &&
      currentPeriod[0] &&
      currentPeriod[1]
    ) {
      updateCookiesProperty(CookiesKeyNames.currentPeriod, currentPeriod);
      updateCookiesProperty(CookiesKeyNames.previousPeriod, previousPeriod);
    }
  }, [currentPeriod, previousPeriod]);

  const formatDate = (date) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    return new Intl.DateTimeFormat("en-UK", options).format(date);
  };

  const handleDateRange = (e) => {
    const curBegin = new Date(e[0]);
    const curEnd = new Date(e[1]);

    curEnd.setHours(23, 59, 59, 999);

    const diff = curEnd.getTime() - curBegin.getTime() + 1;

    const prevBegin = new Date(curBegin.getTime() - diff);
    const prevEnd = new Date(curEnd.getTime() - diff);

    setPreviousPeriod([prevBegin, prevEnd]);
    setCurrentPeriod([curBegin, curEnd]);
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">PUB Home</Link>
              </li>
              <li className="breadcrumb-item">Dashboard</li>
              <li className="breadcrumb-item active" aria-current="page">
                Revenue
              </li>
            </ol>
            <h2 className="main-title mb-0">Revenue Dashboard</h2>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              variant="btn-outline-primary"
              className={`d-flex align-items-center gap-2 btn btn-outline-primary`}
              onClick={() => setIsDateRangePickerOpen(!isDateRangePickerOpen)}
            >
              <span className="fw-semibold">Date Range</span>
              <i className="ri-arrow-right-s-line"></i>
              {currentPeriod && currentPeriod.length === 2
                ? ` ${formatDate(currentPeriod[0])} - ${formatDate(
                    currentPeriod[1]
                  )}`
                : ""}
            </Button>

            {isDateRangePickerOpen && (
              <DatePicker
                isDateRangePickerOpen={isDateRangePickerOpen}
                setIsDateRangePickerOpen={setIsDateRangePickerOpen}
                ranges={Ranges}
                onChange={handleDateRange}
                currentPeriod={currentPeriod}
              />
            )}
          </div>
        </div>
        <Row className="g-3">
          <Col xl="9">
            <RevenueChart
              currentPeriod={currentPeriod}
              previousPeriod={previousPeriod}
            />
          </Col>
          <Col xl="3">
            <Row className="g-3">
              <Col sm="6" xl="12">
                <AverageCPC
                  currentPeriod={currentPeriod}
                  previousPeriod={previousPeriod}
                />
              </Col>
              <Col sm="6" xl="12">
                <AverageCTR
                  currentPeriod={currentPeriod}
                  previousPeriod={previousPeriod}
                />
              </Col>
            </Row>
          </Col>
          <Col xl="12">
            <Totals
              currentPeriod={currentPeriod}
              previousPeriod={previousPeriod}
            />
          </Col>
          <Col sm="6" xl="7">
            <TopCampaigns
              currentPeriod={currentPeriod}
              previousPeriod={previousPeriod}
            />
          </Col>
          <Col xl="5">
            <TopRevenue
              currentPeriod={currentPeriod}
              previousPeriod={previousPeriod}
            />
          </Col>
        </Row>
        <Footer />
      </div>
    </React.Fragment>
  );
}
