import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Modal,
  Row,
  Badge,
  Tooltip,
  Alert,
} from "react-bootstrap";
import { mixpanelInstance } from "../../../../../mixpanel/instance";
import { formatThousand } from "../../../../../helpers/thousand-number-format";
import { placeholderLogoModule } from "../../../../../modules/placeholderLogo";
import {
  editInventoryCampaign,
  getInventoryCampaign,
  getInventoryCampaignByInventoryAndCampaign,
} from "../../../../../services/micro";
import getMinCpc from "../../../../../services/micro/get_min_cpc";
import {
  getCampaign,
  getEstimatedPosition,
  getEstimatedPositionOne,
} from "../../../../../services/adv";
import { GetPubAbbr } from "../../../../../helpers/get-pub-abbr";
import { getPublisher } from "../../../../../services/pub";
import {
  CookiesKeyNames,
  useCookiesContext,
} from "../../../../../contexts/CookiesContext";
import { TaxonomyModule } from "../../../../../modules/taxonomy";

const REVIEW_STATUS = 3;
const DRAFT_STATUS = 4;
const LIVE_STATUS = 1;
const REJECTED_STATUS = 2;

const HTR_STAGE_PUBLISHER_ID = 10;
const HTR_PUBLISHER_ID = 67;
const DEFAULT_ID = 1;

function returnMessage(
  isHTR,
  estimatedMissedPositionOneClickMax,
  estimatedMissedClickMax,
  estClickOpportunity,
  top_bid,
  cpc,
  active,
  bid_amount,
  position,
  min_bid,
  min_increment,
  status
) {
  const publisherIdByAbbr = GetPubAbbr();
  const taxonomy = TaxonomyModule(publisherIdByAbbr);

  if (status === REVIEW_STATUS) {
    return taxonomy.tooltips.ppc_managment_modal.main_lower_tooltip.pending;
  }

  let increment = 0;

  if (top_bid > 0) {
    increment = top_bid + min_increment;
  } else {
    increment = min_bid + min_increment;
  }

  if (!active) {
    let clicks = Number(estimatedMissedPositionOneClickMax);

    if (isNaN(clicks)) {
      return "";
    }

    return taxonomy.tooltips.ppc_managment_modal.main_lower_tooltip.no_bid.replace("{missed_clicks}", clicks).replace("{top_bid_plus_one}", increment);  
    // return `Bid $${increment} to earn the #1 position and generate ~${clicks} clicks over the next 30 days`;
  }

  //if (position === 1) {

    if (
      isNaN(estimatedMissedPositionOneClickMax) &&
      isNaN(estimatedMissedClickMax)
    ) {
      return "";
    }

    if (position <= bid_amount) {
      if (top_bid > 0) {
        increment = top_bid - cpc + min_increment;
      } else {
        increment = min_bid + min_increment;
      }

      if (increment > 0 && estimatedMissedClickMax > 0) {

        if (estimatedMissedClickMax === 0) {
          return "";
        }
        
        return taxonomy.tooltips.ppc_managment_modal.main_lower_tooltip.active_bid_but_not_optimized.replace("{clicks}", estimatedMissedClickMax).replace("{increase_by}", increment).replace("{relative_opportunity}", estClickOpportunity);
        // return `Your current position received ${estimatedMissedClickMax} fewer clicks over the last 30 days than the top position. Increase your bid by $${increment} to receive ${estClickOpportunity}x the click volume.`;
      } else {
        return taxonomy.tooltips.ppc_managment_modal.main_lower_tooltip.optimized.replace("{clicks}", estimatedMissedPositionOneClickMax);
        // return `You are in the top position winning the most clicks in the inventory. Monthly clicks forecast = ${estimatedMissedPositionOneClickMax} clicks/mo`;
      }
    }

    return taxonomy.tooltips.ppc_managment_modal.main_lower_tooltip.optimized.replace("{clicks}", estimatedMissedPositionOneClickMax);
    // return `You are in the top position winning the most clicks in the inventory. Monthly clicks forecast = ${estimatedMissedPositionOneClickMax} clicks/mo`;
  
  // } else {
  //   if (top_bid > 0) {
  //     increment = top_bid + min_increment;
  //   } else {
  //     increment = min_bid + min_increment;
  //   }

  //   let clicks = Number(estimatedMissedPositionOneClickMax);

  //   if (isNaN(clicks)) {
  //     return "";
  //   }

  //   return taxonomy.tooltips.ppc_managment_modal.main_lower_tooltip.no_bid.replace("{missed_clicks}", clicks).replace("{top_bid_plus_one}", increment);
  //   // return `Bid $${increment} to earn the #1 position and generate ~${clicks} clicks over the next 30 days`;
  // }
}

function renderStatus(
  campaign_active,
  status,
  ppc_bid_active,
  ppc_paused,
  inherited_cpc,
  inherited_bid_mode,
  clusterLevel,
  cluster_status
) {
  if (cluster_status) {
    if (cluster_status === "Inactive") {
      return (
        <div className="badge bg-danger">
          <span>Inactive</span>
        </div>
      );
    }

    if (cluster_status === "Paused") {
      return (
        <div className="badge bg-warning">
          <span>Paused</span>
        </div>
      );
    }

    if (cluster_status === "Pending") {
      return (
        <div className="badge bg-secondary">
          <span>Pending</span>
        </div>
      );
    }

    if (cluster_status === "Bid Active") {
      return (
        <div className="badge bg-success">
          <span>Bid Active</span>
        </div>
      );
    }
  }

  if (inherited_bid_mode) {
    if (status === REVIEW_STATUS || status === "REVIEW") {
      return (
        <div className="badge bg-secondary">
          <span>Pending</span>
        </div>
      );
    } else if (status === "REJECTED" || status === REJECTED_STATUS) {
      return (
        <div className="badge bg-danger">
          <span>Inactive</span>
        </div>
      );
    } else {
      if (!ppc_bid_active && clusterLevel != 1 && inherited_cpc !== null) {
        return (
          <div className="badge bg-warning">
            <span>Inherited</span>
          </div>
        );
      } else if (!ppc_bid_active && clusterLevel == 1) {
        return (
          <div className="badge bg-danger">
            <span>Inactive</span>
          </div>
        );
      } else if (!ppc_bid_active && inherited_cpc === null && clusterLevel === 0) {
        return (
          <div className="badge bg-danger">
            <span>Inactive</span>
          </div>
        );
      } else {
        return (
          <div className="badge bg-success">
            <span>Bid Active</span>
          </div>
        );
      }
      // if (inherited_cpc === null) {
      //     return (
      //         <div className="badge bg-success">
      //             <span>Bid Active</span>
      //         </div>
      //     );
      // } else {
      //     return (
      //         <div className="badge bg-warning">
      //             <span>Inherited</span>
      //         </div>
      //     );
      // }
    }
  }

  if (campaign_active && ppc_bid_active && !ppc_paused) {
    return (
      <div className="badge bg-success">
        <span>Live</span>
      </div>
    );
  }

  if (campaign_active && !ppc_bid_active) {
    return (
      <div className="badge bg-danger">
        <span>Inactive</span>
      </div>
    );
  }

  if (campaign_active && ppc_paused) {
    return (
      <div className="badge bg-warning">
        <span>Paused</span>
      </div>
    );
  }

  if (
    campaign_active &&
    !ppc_paused &&
    (status === "DRAFT" ||
      status === DRAFT_STATUS ||
      status === REVIEW_STATUS ||
      status === "REVIEW")
  ) {
    return (
      <div className="badge bg-secondary">
        <span>Pending</span>
      </div>
    );
  }

  return (
    <div className="badge bg-danger">
      <span>Inactive</span>
    </div>
  );
}

function renderStatusStr(
  campaign_active,
  status,
  ppc_bid_active,
  ppc_paused,
  inherited_cpc,
  inherited_bid_mode,
  clusterLevel,
  cluster_status
) {
  if (cluster_status) {
    if (cluster_status === "Inactive") {
      return 'Inactive'
    }

    if (cluster_status === "Paused") {
      return 'Paused'
    }

    if (cluster_status === "Pending") {
      return 'Pending'
    }

    if (cluster_status === "Bid Active") {
      return 'Live'
    }
  }

  if (inherited_bid_mode) {
    if (status === REVIEW_STATUS || status === "REVIEW") {
      return 'Pending'
    } else if (status === "REJECTED" || status === REJECTED_STATUS) {
      return 'Inactive'
    } else {
      if (!ppc_bid_active && clusterLevel != 1 && inherited_cpc !== null) {
        return 'Inherited'
      } else if (!ppc_bid_active && clusterLevel == 1) {
        return 'Inactive'
      } else if (!ppc_bid_active && inherited_cpc === null && clusterLevel === 0) {
        return 'Inactive'
      } else {
        return 'Live'
      }
    }
  }

  if (campaign_active && ppc_bid_active && !ppc_paused) {
    return 'Live'
  }

  if (campaign_active && !ppc_bid_active) {
    return 'Inactive'
  }

  if (campaign_active && ppc_paused) {
    return 'Paused'
  }

  if (
    campaign_active &&
    !ppc_paused &&
    (status === "DRAFT" ||
      status === DRAFT_STATUS ||
      status === REVIEW_STATUS ||
      status === "REVIEW")
  ) {
    return 'Pending'
  }

  return 'Inactive'
}

export default function PPCManagmentModal({
  show,
  handleClose,
  campaignId,
  inventoryId,
  updatePage,
  token,
  setUpdatePage,
  isClusterInventoryCampaign,
  clusterLevel,
  allChildrenCount,
  anyActiveChild,
  anyInactiveParent,
  allowed,
}) {
  const publisherIdByAbbr = GetPubAbbr();
  const taxonomy = TaxonomyModule(publisherIdByAbbr);

  const {
    updateCookiesProperty,
    removeCookiesProperty,
    cookiesData,
    getPathKey,
  } = useCookiesContext();
  const currentSkin = cookiesData[getPathKey()]?.[CookiesKeyNames.skinMode]=== "dark"
    ? "dark"
    : "";

  const [skin, setSkin] = useState(currentSkin);
  const [campaign, setCampaign] = useState();
  const [oldInventoryCampaign, setOldInventoryCampaign] = useState();
  const [inventoryCampaign, setInventoryCampaign] = useState();
  const [estimatedPosition, setEstimatedPosition] = useState();
  const [canPositionBeEstimated, setCanPositionBeEstimated] = useState(false);
  const [publisher, setPublisher] = useState();
  const [loading, setLoading] = useState(true);

  // console.log("anyInactiveParent", anyInactiveParent);

  useEffect(() => {
    if (inventoryId && campaignId) {
      getInventoryCampaignByInventoryAndCampaign(inventoryId, campaignId).then(
        (response) => {
          if (response) {
            setInventoryCampaign(response.data);
            setOldInventoryCampaign(response.data);

            let cpc = response.data?.inherited_cpc ? response.data?.inherited_cpc : response.data?.cpc;

            if (!cpc || cpc < response.data?.min_bid) {
              cpc = response.data?.min_bid;
            }

            getEstimatedPosition(
              response.data?.id,
              cpc,
              response.data?.ppc_bid_active
            ).then((res) => {
              setEstimatedPosition(res.data);
              setCanPositionBeEstimated(true);
            });
          }
        }
      );

      getCampaign(campaignId, token).then((response) => {
        if (response) {
          setCampaign(response.data);
        }
      });
    }

    if (
      publisherIdByAbbr &&
      publisherIdByAbbr !== "undefined" &&
      publisherIdByAbbr !== "null"
    ) {
      getPublisher(publisherIdByAbbr).then((response) => {
        if (response) {
          setPublisher(response.data);
        }
      });
    } else {
      const publisherId = cookiesData[getPathKey()]?.[CookiesKeyNames.publisherId];

      getPublisher(publisherId).then((response) => {
        if (response) {
          setPublisher(response.data);
        }
      });
    }
  }, [inventoryId, campaignId, token]);

  const isHTR =
    publisherIdByAbbr === HTR_PUBLISHER_ID ||
    publisherIdByAbbr === HTR_STAGE_PUBLISHER_ID;
  const isDefault = publisherIdByAbbr === DEFAULT_ID;
  const estimatePosition =
    canPositionBeEstimated || (campaign?.ppc_active && !campaign?.ppc_paused);

  const handleSave = () => {
    setLoading(true);

    if (inventoryCampaign?.custom_url?.length > 0) {
      if (inventoryCampaign?.custom_url?.length > 255) {
        toast.warn("Custom URL can't be longer than 255 characters");
        return false;
      }

      if (inventoryCampaign?.custom_url?.includes(" ")) {
        toast.warn("Custom URL can't contain spaces");
        return false;
      }

      if (!inventoryCampaign?.custom_url?.includes("http")) {
        toast.warn("Custom URL must contain http or https");
        return false;
      }

      if (inventoryCampaign?.custom_url?.includes("http://")) {
        toast.warn("Custom URL must contain https");
        return false;
      }

      if (!inventoryCampaign?.custom_url?.includes(".")) {
        toast.warn("Custom URL must contain a domain");
        return false;
      }
    }

    const trackInventoryCampaignChanging = (message) => {
      mixpanelInstance.track(message, {
        campaignId,
        inventoryCampaignId: inventoryCampaign.id,
        cpc: Number(inventoryCampaign.cpc),
        custom_url: inventoryCampaign.custom_url,
        ppc_bid_active: inventoryCampaign.ppc_bid_active,
      });
    };

    editInventoryCampaign(inventoryCampaign.id, {
      cpc: Number(inventoryCampaign.cpc),
      custom_url: inventoryCampaign.custom_url,
      position:
        inventoryCampaign.cpc !== oldInventoryCampaign.cpc
          ? estimatedPosition?.estimated_position
          : oldInventoryCampaign.position,
      ppc_bid_active: inventoryCampaign.ppc_bid_active,
    })
      .then((res) => {
        handleClose();

        toast.success("Queue item saved successfully");

        trackInventoryCampaignChanging(
          `inventoryCampaign ${inventoryCampaign.id} saved successfully`
        );

        setUpdatePage(updatePage + 1);
      })
      .catch((err) => {
        toast.error("Error saving queue item");

        trackInventoryCampaignChanging(
          `Error saving inventoryCampaign ${inventoryCampaign.id}`
        );
      });
  };

  const updateCPC = (sign, min_bid) => {
    const change =
      sign === "plus"
        ? Number(publisher?.min_increment)
        : -Number(publisher?.min_increment);

    let cpc = inventoryCampaign?.inherited_cpc ? inventoryCampaign?.inherited_cpc : inventoryCampaign?.cpc;

    if (!cpc || cpc < inventoryCampaign?.min_bid) {
      cpc = inventoryCampaign?.min_bid;
    }
    
    let newCpc = Number((cpc + change).toFixed(1));

    if (sign === "minus" && cpc <= min_bid) {
      toast.warn("CPC can't be lower than the minimum CPC");
      newCpc = min_bid;
    }

    if (sign === "plus" && cpc < min_bid) {
      toast.warn("CPC can't be lower than the minimum CPC");
      newCpc = min_bid;
    }

    if (inventoryCampaign.inherited_cpc) {
      setInventoryCampaign({ ...inventoryCampaign, inherited_cpc: newCpc });
    } else {
      setInventoryCampaign({ ...inventoryCampaign, cpc: newCpc });
    }

    const blockCpc = document.getElementById("blockCpc");
    blockCpc.value = newCpc;

    mixpanelInstance.track(
      `Change cpc for ${campaign?.name} in bid management popup`,
      {
        campaignId,
        sign,
        newCpc,
      }
    );
  };

  const handleSubmitBid = () => {
    setLoading(true);

    let submit_data = {
      cpc: Number(inventoryCampaign.cpc),
      ppc_bid_active: inventoryCampaign.ppc_bid_active,
      custom_url: inventoryCampaign.custom_url,
    };

    if (inventoryCampaign.inventory_campaign_status !== 1) {
      submit_data = {
        ...submit_data,
        inventory_campaign_status: REVIEW_STATUS,
      };
    }

    editInventoryCampaign(inventoryCampaign.id, submit_data)
      .then((res) => {
        handleClose();

        toast.success("Bid submitted successfully");

        setUpdatePage(updatePage + 1);
      })
      .catch((err) => {
        toast.error("Error submitting bid");
      });
  };

  const handleWithdrawBid = () => {
    setLoading(true);

    editInventoryCampaign(inventoryCampaign.id, {
      inventory_campaign_status: DRAFT_STATUS,
    })
      .then((res) => {
        handleClose();

        toast.success("Bid withdrawn successfully");

        setUpdatePage(updatePage + 1);
      })
      .catch((err) => {
        toast.error("Error withdrawing bid");
      });
  };

  const trackBidChange = (message, inventoryCampaignId) => {
    mixpanelInstance.track(
      `${message} for inventory campaign ${inventoryCampaignId}`,
      {
        campaignId,
        inventoryCampaignId,
      }
    );
  };

  const handleDeactivateBid = (id) => {
    setLoading(true);

    const isClicked =
      cookiesData[getPathKey()]?.[CookiesKeyNames.clickedDeactivate];

    if (!isClicked) {
      updateCookiesProperty(CookiesKeyNames.clickedDeactivate, "true");

      editInventoryCampaign(id, {
        ppc_bid_active: false,
      })
        .then((res) => {
          toast.success("Bid deactivated successfully");
          trackBidChange("Bid deactivated successfully", id);

          setUpdatePage(updatePage + 1);
        })
        .catch((err) => {
          toast.error("Error deactivating bid");
          removeCookiesProperty("clickedDeactivate");
          trackBidChange("Error deactivating bid", id);
        });
    }
  };

  const onChangeCpc = (e) => {
    setTimeout(() => {
      getMinCpc(
        "inventory_campaigns",
        null,
        inventoryCampaign.inventory_id,
        inventoryCampaign.campaign_id
      ).then((res) => {
        let updatedCpc = 0;

        if (Number(e.target.value) < res.data || isNaN(e.target.value)) {
          toast.warn("CPC can't be lower than the minimum CPC");
          setInventoryCampaign({ ...inventoryCampaign, cpc: res.data });
          const blockCpc = document.getElementById("blockCpc");
          blockCpc.value = res.data;
          updatedCpc = res.data;
        } else {
          const newCpc = Number(e.target.value);
          setInventoryCampaign({ ...inventoryCampaign, cpc: newCpc });
          const blockCpc = document.getElementById("blockCpc");
          blockCpc.value = newCpc;
          updatedCpc = newCpc;
        }

        getEstimatedPosition(
          inventoryCampaign.id,
          updatedCpc,
          inventoryCampaign.ppc_bid_active
        )
          .then((res) => {
            setEstimatedPosition(res.data);
            setCanPositionBeEstimated(true);
          })
          .catch((err) => {
            console.log("Error fetching estimated position");
          });
      });
    }, 2000);
  };

  const disableCPC = (inventoryCampaign) => {
    if (
      allowed
    ) {
      if (
        publisher?.cluster_enforce_mode_enabled &&
        publisher?.cluster_inherited_bid_mode_enabled &&
        anyInactiveParent
      ) {
        return true;
      }
    }

    return false;
  };

  const onChangeCpcByButton = (sign) => {
    setTimeout(() => {
      getMinCpc(
        "inventory_campaigns",
        null,
        inventoryCampaign.inventory_id,
        inventoryCampaign.campaign_id
      ).then((res) => {
        let cpc = inventoryCampaign?.inherited_cpc ? inventoryCampaign?.inherited_cpc : inventoryCampaign?.cpc;

        if (!cpc || cpc < inventoryCampaign?.min_bid) {
          cpc = inventoryCampaign?.min_bid;
        }

        if (sign === "minus" && cpc <= res.data) {
          toast.warn("CPC can't be lower than the minimum CPC");
          setInventoryCampaign({ ...inventoryCampaign, cpc: res.data });
          const blockCpc = document.getElementById("blockCpc");
          blockCpc.value = res.data;
          return;
        }

        updateCPC(sign, res.data);

        let diff = 0;
        if (sign === "plus") {
          diff = Number(publisher?.min_increment);
        } else {
          diff = -Number(publisher?.min_increment);
        }

        getEstimatedPosition(
          inventoryCampaign.id,
          Number((cpc + diff).toFixed(1)),
          inventoryCampaign.ppc_bid_active
        )
          .then((res_pos) => {
            setEstimatedPosition(res_pos.data);
            setCanPositionBeEstimated(true);
          })
          .catch((err) => {
            console.log("Error fetching estimated position");
          });
      });
    }, 200);
  };

  const onChangePPCActive = (e) => {
    mixpanelInstance.track(
      `Change ppc active toggle for ${campaign?.name} in bid management popup`,
      {
        campaignId,
        ppcActive: e.target.checked,
      }
    );

    if (
      publisher?.cluster_enforce_mode_enabled &&
      publisher?.cluster_inherited_bid_mode_enabled &&
      clusterLevel === 1
    ) {
      if (!e.target.checked && allChildrenCount > 0 && anyActiveChild) {
        toast.warn(
          "If you want to deactivate this bid, you need to deactivate all sub-clusters first"
        );

        return;
      }
    }

    if (
      publisher?.cluster_inherited_bid_mode_enabled &&
      publisher?.cluster_enforce_mode_enabled &&
      clusterLevel !== 1 &&
      !!clusterLevel
    ) {
      if ((!e.target.checked && allChildrenCount > 0 && anyActiveChild) || !allowed) {
        toast.warn(
          "If you want to deactivate this bid, you need to deactivate all sub-clusters first"
        );

        return;
      }
    }

    if (
      publisher?.cluster_enforce_mode_enabled &&
      !publisher?.cluster_inherited_bid_mode_enabled &&
      !!clusterLevel
    ) {
      if (!e.target.checked && allChildrenCount > 0 && !anyActiveChild) {
        toast.warn(
          "If you want to deactivate this bid, you need to deactivate all sub-clusters first"
        );

        return;
      }
    }

    if (
      !publisher?.cluster_enforce_mode_enabled &&
      publisher?.cluster_inherited_bid_mode_enabled &&
      !!clusterLevel
    ) {
      if (!e.target.checked && allChildrenCount > 0 && !anyActiveChild) {
        toast.warn(
          "If you deactivate this bid, all sub-clusters will be inherited from the parent cluster"
        );
      }
    }

    setInventoryCampaign({
      ...inventoryCampaign,
      ppc_bid_active: e.target.checked,
    });

    getEstimatedPosition(
      inventoryCampaign.id,
      Number(inventoryCampaign.cpc),
      e.target.checked
    )
      .then((res) => {
        setEstimatedPosition(res.data);
        setCanPositionBeEstimated(true);
      })
      .catch((err) => {
        console.log("Error fetching estimated position");
      });
  };

  const onChangeCustomUrl = (e) => {
    setInventoryCampaign({
      ...inventoryCampaign,
      custom_url: e.target.value.trim(),
    });
  };

  const tooltipContent = returnMessage(
    isHTR,
    estimatedPosition?.position_one_missed_clicks?.est_clicks,
    estimatedPosition?.missed_clicks?.est_missed_clicks,
    estimatedPosition?.missed_clicks?.est_click_opportunity,
    estimatedPosition?.top_bid
      ? estimatedPosition?.top_bid
      : inventoryCampaign?.stat?.top_cpc_bid,
    inventoryCampaign?.cpc,
    inventoryCampaign?.ppc_bid_active,
    estimatedPosition?.bid_amount,
    estimatedPosition?.current_position,
    inventoryCampaign?.min_bid,
    publisher?.min_increment,
    inventoryCampaign?.inventory_campaign_status
  );

  let default_value = inventoryCampaign?.inherited_cpc ? inventoryCampaign?.inherited_cpc : inventoryCampaign?.cpc;

  if (!default_value || default_value < inventoryCampaign?.min_bid) {
    default_value = inventoryCampaign?.min_bid;
  }

  const renderPosition = (estimatedPosition, inventoryCampaign) => {
    
    if (!estimatedPosition) {
      if (inventoryCampaign?.position) {
        return "#" + inventoryCampaign?.position;
      }
    } else {
      if (
        estimatedPosition?.current_position > estimatedPosition?.bid_amount &&
        estimatedPosition?.estimated_position > estimatedPosition?.bid_amount
      ) {
        return (
          <div className="d-flex align-items-center">
            <small>
              Not shown
            </small>
          </div>
        );
      }

      if (
        estimatedPosition?.current_position != estimatedPosition?.estimated_position
      ) {
        if (estimatedPosition?.bid_amount <= estimatedPosition?.estimated_position) {
          return (
            <div className="d-flex align-items-center">
              <small>
                {estimatedPosition?.current_position !== 0
                  ? "#" + estimatedPosition?.current_position
                  : "Not shown"}
              </small>
            </div>
          );
        } else if (estimatedPosition?.bid_amount <= estimatedPosition?.current_position) {
          return (
            <div className="d-flex align-items-center">
              <small style={{ marginRight: 5 }}>
                Not shown
              </small>
              <i
                className="ri-arrow-right-line"
                data-bs-toggle="tooltip"
                title="Estimated position"
              ></i>
              <span style={{ marginLeft: 5 }}>
                #{estimatedPosition?.estimated_position}
              </span>
            </div>
          );
        } else {
          return (
            <div className="d-flex align-items-center">
              {estimatedPosition?.current_position ? (
                <>
                  <small style={{ marginRight: 5 }}>
                    {"#" + estimatedPosition?.current_position}
                  </small>
                  <i
                    className="ri-arrow-right-line"
                    data-bs-toggle="tooltip"
                    title="Estimated position"
                  ></i>
                  <span style={{ marginLeft: 5 }}>
                    #{estimatedPosition?.estimated_position}
                  </span>
                </>
              ) : (
                <>
                  <span style={{ marginLeft: 5 }}>
                    #{estimatedPosition?.estimated_position}
                  </span>
                </>
              )}
            </div>
          );
        }
      } else {
        return (
          <div className="d-flex align-items-center">
            <small>
              {estimatedPosition?.current_position !== 0
                ? "#" + estimatedPosition?.current_position
                : "Not shown"}
            </small>
          </div>
        );
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton className="card-one pb-2">
        <Modal.Title>
          <div className="d-flex align-items-center">
            <span>{inventoryCampaign?.inventory?.name}</span>
            {isClusterInventoryCampaign && (
              <Badge bg="secondary" className="ms-2">
                Level {clusterLevel} Topic Cluster
              </Badge>
            )}
          </div>
          <p
            className="d-flex gap-2 mb-0"
            style={{
              fontSize: "12px",
              fontWeight: 400,
              color: "#6e7985",
            }}
          >
            {HTR_PUBLISHER_ID !== publisherIdByAbbr &&
            HTR_STAGE_PUBLISHER_ID !== publisherIdByAbbr &&
            DEFAULT_ID !== publisherIdByAbbr
              ? inventoryCampaign?.inventory?.related_categories_ids
                  ?.map((category) => {
                    return campaign?.columns
                      ?.find((c) => c.name === "related_categories_ids")
                      ?.options?.find((o) => o.value === category)?.label;
                  })
                  .filter((category) => category)
                  .join(", ")
              : ""}
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="card-one pt-0">
        <Row>
          <Col
            sm="12"
            className="text-left"
            onClick={() =>
              mixpanelInstance.track(
                "Placement 1 button clicked in bid management popup",
                {
                  campaignId,
                }
              )
            }
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                inventoryCampaign?.inventory ? (
                  <Tooltip placement="bottom">
                    {inventoryCampaign?.inventory.anchor_name}
                  </Tooltip>
                ) : (
                  <></>
                )
              }
            >
              <a
                href={inventoryCampaign?.inventory.url}
                target="_blank"
                className="btn btn-sm btn-outline-primary me-1"
              >
                Placement 1
                <i
                  className="ri-external-link-line"
                  style={{ marginLeft: "5px" }}
                ></i>
              </a>
            </OverlayTrigger>
            {inventoryCampaign?.alt_versions &&
              inventoryCampaign?.alt_versions.length > 0 &&
              inventoryCampaign?.alt_versions.map((alt_version, index) => {
                return (
                  <OverlayTrigger
                    key={index}
                    placement="bottom"
                    overlay={
                      alt_version?.anchor_name ? (
                        <Tooltip placement="bottom">
                          {alt_version?.anchor_name}
                        </Tooltip>
                      ) : (
                        <></>
                      )
                    }
                  >
                    <a
                      href={alt_version.custom_url}
                      target="_blank"
                      className="btn btn-sm btn-outline-primary me-1"
                    >
                      {`Placement ${index + 2}`}
                      <i
                        className="ri-external-link-line"
                        style={{ marginLeft: "5px" }}
                      ></i>
                    </a>
                  </OverlayTrigger>
                );
              })}
          </Col>
          <Col sm="12">
            <div className="divider mt-2">
              <span>Bid Management</span>
            </div>
          </Col>
          <Col sm="12">
            <Form>
              <Row className="">
                <Col sm="6">
                  <div className="text-center">
                    <Form.Label htmlFor="blockCpc" className="dollar-sign">
                      $
                    </Form.Label>
                    <Form.Control
                      id="blockCpc"
                      placeholder={inventoryCampaign?.min_bid}
                      onChange={(e) => onChangeCpc(e)}
                      defaultValue={
                        default_value
                      }
                      disabled={
                        disableCPC(inventoryCampaign)
                      }
                      type="text"
                      className="cpc-field"
                    />
                    <Button
                      variant="white"
                      className="button-icon-button minus"
                      disabled={
                        disableCPC(inventoryCampaign)
                      }
                      onClick={() => onChangeCpcByButton("minus")}
                    >
                      <i className="ri-indeterminate-circle-line button-icon"></i>
                    </Button>
                    <Button
                      variant="white"
                      className="button-icon-button plus"
                      disabled={
                        disableCPC(inventoryCampaign)
                      }
                      onClick={() => onChangeCpcByButton("plus")}
                    >
                      <i className="ri-add-circle-line button-icon"></i>
                    </Button>
                  </div>
                  <div className="auction-info">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip placement="bottom">
                          {
                            inventoryCampaign?.ppc_bid_active ? (
                              taxonomy.tooltips.ppc_managment_modal.ppc_bid_active.active
                            ) : (
                              taxonomy.tooltips.ppc_managment_modal.ppc_bid_active.inactive
                            )
                          }
                        </Tooltip>
                      }
                    >
                      <div className="auction-info-item">
                        {inventoryCampaign?.ppc_bid_active ? (
                          <i className="ri-cursor-line"></i>
                        ) : (
                          <i className="ri-cursor-line text-danger"></i>
                        )}
                        {inventoryCampaign?.ppc_bid_active ? (
                          <span className="">PPC Bid Active</span>
                        ) : (
                          <span className="text-danger">PPC Bid Inactive</span>
                        )}
                        <span className="switch fw-bold">
                          <div className="form-check form-switch">
                            <input
                              type="checkbox"
                              className={
                                inventoryCampaign?.ppc_bid_active
                                  ? "form-check-input bg-primary"
                                  : "form-check-input bg-danger unchecked"
                              }
                              checked={inventoryCampaign?.ppc_bid_active}
                              onChange={(e) => onChangePPCActive(e)}
                            />
                          </div>
                        </span>
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip placement="bottom">
                          {
                            renderStatusStr(
                              campaign?.ppc_active,
                              inventoryCampaign?.inventory_campaign_status,
                              inventoryCampaign?.ppc_bid_active,
                              campaign?.ppc_paused,
                              inventoryCampaign?.inherited_cpc,
                              publisher?.cluster_inherited_bid_mode_enabled,
                              clusterLevel,
                              inventoryCampaign?.cluster_status
                            ).includes("Paused") ? (
                              taxonomy.tooltips.ppc_managment_modal.bid_status.paused
                            ) : (
                              <></>
                            )
                          }
                          {
                            renderStatusStr(
                              campaign?.ppc_active,
                              inventoryCampaign?.inventory_campaign_status,
                              inventoryCampaign?.ppc_bid_active,
                              campaign?.ppc_paused,
                              inventoryCampaign?.inherited_cpc,
                              publisher?.cluster_inherited_bid_mode_enabled,
                              clusterLevel,
                              inventoryCampaign?.cluster_status
                            ).includes("Live") ? (
                              taxonomy.tooltips.ppc_managment_modal.bid_status.live
                            ) : (
                              <></>
                            )
                          }
                          {
                            renderStatusStr(
                              campaign?.ppc_active,
                              inventoryCampaign?.inventory_campaign_status,
                              inventoryCampaign?.ppc_bid_active,
                              campaign?.ppc_paused,
                              inventoryCampaign?.inherited_cpc,
                              publisher?.cluster_inherited_bid_mode_enabled,
                              clusterLevel,
                              inventoryCampaign?.cluster_status
                            ).includes("Pending") ? (
                              taxonomy.tooltips.ppc_managment_modal.bid_status.pending
                            ) : (
                              <></>
                            )
                          }
                          {
                            renderStatusStr(
                              campaign?.ppc_active,
                              inventoryCampaign?.inventory_campaign_status,
                              inventoryCampaign?.ppc_bid_active,
                              campaign?.ppc_paused,
                              inventoryCampaign?.inherited_cpc,
                              publisher?.cluster_inherited_bid_mode_enabled,
                              clusterLevel,
                              inventoryCampaign?.cluster_status
                            ).includes("Inactive") ? (
                              taxonomy.tooltips.ppc_managment_modal.bid_status.inactive
                            ) : (
                              <></>
                            )
                          }
                        </Tooltip>
                      }
                    >
                      <div className="auction-info-item">
                        <i className="ri-information-line"></i>
                        <span>Bid Status</span>
                        <span className="switch">
                          <div style={{ marginRight: "10px" }}>
                            {
                              renderStatus(
                                campaign?.ppc_active,
                                inventoryCampaign?.inventory_campaign_status,
                                inventoryCampaign?.ppc_bid_active,
                                campaign?.ppc_paused,
                                inventoryCampaign?.inherited_cpc,
                                publisher?.cluster_inherited_bid_mode_enabled,
                                clusterLevel,
                                inventoryCampaign?.cluster_status
                              )
                            }
                          </div>
                        </span>
                      </div>
                    </OverlayTrigger>
                    <div className="auction-info-item">
                      <i className="ri-auction-line"></i>
                      <span>
                        {
                          estimatedPosition?.current_position != inventoryCampaign?.position ? "Estimated Position" : "Current Position"
                        }
                      </span>
                      <span className="number inline-block">
                        {
                          renderPosition(
                            estimatedPosition,
                            inventoryCampaign
                          )
                        }
                      </span>
                    </div>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip placement="bottom">
                          The minimum required bid for this inventory.
                        </Tooltip>
                      }
                    >
                      <div className="auction-info-item">
                        <i className="ri-coins-line"></i>
                        <span>Minimum Bid</span>
                        <span className="number">
                          ${inventoryCampaign?.min_bid}
                        </span>
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip placement="bottom">
                          The current top bid on this inventory.
                        </Tooltip>
                      }
                    >
                      <div className="auction-info-item">
                        <i className="ri-vip-line"></i>
                        <span>Current Top Bid</span>
                        <span className="number">
                          {/* ${
                                    inventoryCampaign?.stat?.top_cpc_bid
                                } */}
                          $
                          {estimatedPosition?.top_bid != "0"
                            ? estimatedPosition?.top_bid
                            : inventoryCampaign?.stat?.top_cpc_bid == "0"
                            ? inventoryCampaign?.min_bid
                            : inventoryCampaign?.stat?.top_cpc_bid}
                        </span>
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip placement="bottom">
                          The amount of views this inventory received over the
                          last 30 days.
                        </Tooltip>
                      }
                    >
                      <div className="auction-info-item">
                        <i className="ri-funds-line"></i>
                        <span>30 Day Views</span>
                        <span className="number">
                          {formatThousand(inventoryCampaign?.stat?.total_views)}
                        </span>
                      </div>
                    </OverlayTrigger>
                  </div>
                </Col>
                <Col sm="6" className="d-none d-sm-block">
                  <div className="auction-preview">
                    <Row>
                      <Col xs="12">
                        <div id="bid-management">
                          <div className="mb-3">
                            <span className="section-title">
                              Auction Preview
                            </span>
                            {!inventoryCampaign?.ppc_bid_active && (
                              <Badge bg="danger" className="ms-2 inline-block">
                                PPC Inactive
                              </Badge>
                            )}
                          </div>
                          <ol className="vendor-list">
                            {estimatedPosition?.inventory_campaigns?.map(
                              (item, index) => {
                                return (
                                  <li
                                    key={index}
                                    data-position={item.position}
                                    className={
                                      item.id === inventoryCampaign?.id
                                        ? "vendor current"
                                        : "vendor"
                                    }
                                  >
                                    <div className="d-sm-flex align-items-center">
                                      <div
                                        className="avatar avatar-xs"
                                        style={{ minWidth: 20 }}
                                      >
                                        {item.id === inventoryCampaign?.id ? (
                                          campaign?.logo_file ? (
                                            <img
                                              src={campaign?.logo_file}
                                              alt="logo"
                                              className="avatar-img"
                                            />
                                          ) : (
                                            placeholderLogoModule(
                                              publisherIdByAbbr,
                                              currentSkin,
                                              item.logo
                                            )
                                          )
                                        ) : (
                                          <div className="avatar-img" />
                                        )}
                                      </div>
                                      <span className="ms-2 fs-sm">
                                        {/* // TODO: 
                                                    - if placement name is defined in alt ver => show it
                                                    - check why the est list is not showing
                                                */}
                                        {item.id === inventoryCampaign?.id ? (
                                          <strong>{item.inventory_name}</strong>
                                        ) : (
                                          <span>{item.inventory_name}</span>
                                        )}
                                        {item.id === inventoryCampaign?.id &&
                                        inventoryCampaign.inventory_campaign_status ===
                                          REVIEW_STATUS ? (
                                          <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                              <Tooltip placement="bottom">
                                                We’re currently reviewing this
                                                bid. You will feature on this
                                                inventory if/when it is
                                                approved.
                                              </Tooltip>
                                            }
                                          >
                                            <span className="badge bg-outline-warning ms-2">
                                              Under Review
                                            </span>
                                          </OverlayTrigger>
                                        ) : (
                                          <></>
                                        )}
                                        {item.id === inventoryCampaign?.id &&
                                        inventoryCampaign.inventory_campaign_status ===
                                          DRAFT_STATUS ? (
                                          <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                              <Tooltip placement="bottom">
                                                You need to submit this bid for
                                                review to feature on this
                                                inventory.
                                              </Tooltip>
                                            }
                                          >
                                            <span className="badge bg-outline-secondary ms-2">
                                              Draft
                                            </span>
                                          </OverlayTrigger>
                                        ) : (
                                          <></>
                                        )}
                                        {item.id === inventoryCampaign?.id &&
                                        inventoryCampaign.inventory_campaign_status ===
                                          LIVE_STATUS ? (
                                          <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                              <Tooltip placement="bottom">
                                                You are currently live on this
                                                inventory. As long as your
                                                position is high enough you will
                                                be featured.
                                              </Tooltip>
                                            }
                                          >
                                            <span className="badge bg-outline-success ms-2">
                                              Approved
                                            </span>
                                          </OverlayTrigger>
                                        ) : (
                                          <></>
                                        )}
                                        {item.id === inventoryCampaign?.id &&
                                        inventoryCampaign.inventory_campaign_status ===
                                          REJECTED_STATUS ? (
                                          <span className="badge bg-outline-danger ms-2">
                                            Rejected
                                          </span>
                                        ) : (
                                          <></>
                                        )}
                                      </span>
                                    </div>
                                  </li>
                                );
                              }
                            )}
                          </ol>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              {tooltipContent.length > 0 && (
                <Row className="mt-2 mb-2">
                  <Col sm="12">
                    <div className="ppc-danger-system-alert show mb-0">
                      <div className="items-center w-100 d-flex align-items-center">
                        <div className="icon" style={{ marginRight: 10 }}>
                          <i
                            className="ri-information-line"
                            style={{ fontSize: 20 }}
                          ></i>
                        </div>
                        {tooltipContent}
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
              <Row className="mt-2">
                <Col sm="12">
                  <div className="divider mt-2">
                    <span>Landing Page URL</span>
                    {/* <span>Landing Page URL(s)</span> */}
                  </div>
                </Col>
                <Col sm="12" className="text-center">
                  <Form className="row align-items-center">
                    <div className="col-12">
                      <div className="input-group">
                        <Form.Control
                          type="url"
                          id="landingPageLink"
                          placeholder={campaign?.ppc_default_url}
                          value={inventoryCampaign?.custom_url}
                          onChange={(e) => onChangeCustomUrl(e)}
                        />
                      </div>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col sm="12" className="text-center">
            {inventoryCampaign?.inventory_campaign_status !== DRAFT_STATUS &&
            inventoryCampaign?.inventory_campaign_status !== REVIEW_STATUS ? (
              <Button
                variant={
                  oldInventoryCampaign?.cpc === inventoryCampaign?.cpc &&
                  oldInventoryCampaign?.custom_url ===
                    inventoryCampaign?.custom_url &&
                  oldInventoryCampaign?.ppc_bid_active ===
                    inventoryCampaign?.ppc_bid_active
                    ? "outline-primary"
                    : "primary"
                }
                className="block w-100 mt-3"
                onClick={handleSave}
                disabled={
                  oldInventoryCampaign?.cpc === inventoryCampaign?.cpc &&
                  oldInventoryCampaign?.custom_url ===
                    inventoryCampaign?.custom_url &&
                  oldInventoryCampaign?.ppc_bid_active ===
                    inventoryCampaign?.ppc_bid_active &&
                  !publisher?.cluster_bidding_mode_enabled
                }
              >
                {oldInventoryCampaign?.cpc !== inventoryCampaign?.cpc
                  ? inventoryCampaign?.ppc_bid_active
                    ? "Set Bid"
                    : "Save (Bid Inactive)"
                  : "Save"}
              </Button>
            ) : (
              <>
                {inventoryCampaign?.inventory_campaign_status ===
                DRAFT_STATUS ? (
                  <>
                    <Row className="g-2">
                      {/* <Col sm="6">
                                    <Button
                                    variant="outline-primary"
                                    className="block w-100 mt-3"
                                    onClick={handleSave}
                                    disabled={
                                        oldInventoryCampaign?.cpc ===
                                        inventoryCampaign?.cpc &&
                                        oldInventoryCampaign?.custom_url ===
                                        inventoryCampaign?.custom_url &&
                                        oldInventoryCampaign?.ppc_bid_active ===
                                        inventoryCampaign?.ppc_bid_active
                                    }
                                    >
                                    Save
                                    </Button>
                                </Col> */}
                      <Col sm="12">
                        <Button
                          variant="primary"
                          className="block w-100 mt-3"
                          onClick={handleSubmitBid}
                          disabled={inventoryCampaign?.ppc_bid_active === false}
                        >
                          Submit Bid
                        </Button>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}

                {inventoryCampaign?.inventory_campaign_status ===
                REVIEW_STATUS ? (
                  <>
                    <Row className="g-2">
                      <Col sm="6">
                        <Button
                          variant="outline-primary"
                          className="block w-100 mt-3"
                          onClick={handleSave}
                          disabled={
                            oldInventoryCampaign?.cpc ===
                              inventoryCampaign?.cpc &&
                            oldInventoryCampaign?.custom_url ===
                              inventoryCampaign?.custom_url &&
                            oldInventoryCampaign?.ppc_bid_active ===
                              inventoryCampaign?.ppc_bid_active
                          }
                        >
                          Save
                        </Button>
                      </Col>
                      <Col sm="6">
                        <Button
                          variant="danger"
                          className="block w-100 mt-3"
                          onClick={handleWithdrawBid}
                        >
                          Withdraw Bid
                        </Button>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
            {!campaign?.ppc_active && (
              <Alert variant="danger" className="mt-3 mb-0">
                <strong>Your campaign is inactive</strong> at the moment. This
                is just a preview of the auction.
              </Alert>
            )}
            {campaign?.ppc_active && campaign?.ppc_paused && (
              <Alert variant="warning" className="mt-3 mb-0">
                <strong>Your campaign is paused</strong> at the moment. This is
                just a preview of the auction.
              </Alert>
            )}
            {
              inventoryCampaign?.inventory_campaign_status === DRAFT_STATUS && inventoryCampaign?.ppc_bid_active === false
               ? (
                <Alert variant="warning" className="mt-3 mb-0">
                  <strong>Your bid is in draft status</strong> at the moment. This is just a preview of the auction. Activate your bid & submit it for review to feature on this inventory.
                </Alert>
              ) : (
                <></>
              )
            }
            {
              inventoryCampaign?.inventory_campaign_status === DRAFT_STATUS && inventoryCampaign?.ppc_bid_active === true
               ? (
                <Alert variant="warning" className="mt-3 mb-0">
                  <strong>Your bid is in draft status</strong> at the moment. This is just a preview of the auction. Submit your bid for review to feature on this inventory.
                </Alert>
              ) : (
                <></>
              )
            }
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
