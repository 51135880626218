import axios from "axios";

export const getPublisherCategories = async (token, publisher_id) => {
  const url = new URL(
    process.env.REACT_APP_HUB_URL + "/api/publisher-categories/by-secret"
  );
  url.searchParams.append("publisher_id", publisher_id);
  url.searchParams.append("token", token);
  url.searchParams.append(
    "secret_token",
    process.env.REACT_APP_ONBOARDING_SECRET_KEY
  );
  const response = await axios.get(url);
  console.log("response", response);
  return response.data;
};
