import { createNewCampaign, createNewCustomer } from "../services/adv";
import { createBillingMethod, createTranasction } from "../services/finance";
import { createInventoryCampaign, createUser } from "../services/micro";
import {
  createNewCategory,
  createNewInventory,
  createNewWebsite,
} from "../services/pub";
import createNewApplication from "../services/pub/create_new_applications";

// ... existing code ...

async function createNewRecord(table_name, values) {
  try {
    if (!table_name) throw new Error("Table name is required");
    if (!values) throw new Error("Values are required");

    const cleanedValues = Object.fromEntries(
      Object.entries(values).filter(([_, value]) => value !== null)
    );

    // table name to handler function
    const tableHandlers = {
      publisher_categories: createNewCategory,
      publisher_websites: createNewWebsite,
      publisher_inventory: createNewInventory,
      clients_accounts: createNewCustomer,
      client_campaigns: createNewCampaign,
      users: createUser,
      inventory_campaigns: createInventoryCampaign,
      transactions: createTranasction,
      billing_methods: createBillingMethod,
      client_applications: createNewApplication,
    };

    const handler = tableHandlers[table_name];
    if (!handler) {
      throw new Error(`Unknown table name: ${table_name}`);
    }

    return await handler(cleanedValues);
  } catch (error) {
    console.error("Error in createNewRecord:", error);
    throw error;
  }
}

export default createNewRecord;