import React from "react";
import { Button } from "react-bootstrap";
import { GetPubAbbr } from "../../../../../helpers/get-pub-abbr";
import { TaxonomyModule } from "../../../../../modules/taxonomy";

export function ActionRenderer({ value }) {
  if (!value) {
    return null;
  }

  const status = value.toUpperCase();

  if (status === "INACTIVE" || status === "DRAFT") {
    return (
      <button className="btn bg-success btn-sm text-white" style={{marginLeft: '5px', width: '100px'}}>
        Start Bidding
      </button>
    );
  } else {
    return (
      <div>
        <Button variant="outline-primary" size="sm" className="fs-11" style={{marginLeft: '5px', width: '100px'}}>
          Update Bid
        </Button>
      </div>
    );
  }
}

export function ActionCellColumnHeaderTooltipRenderer() {
  const publisherIdByAbbr = GetPubAbbr();
  const taxonomy = TaxonomyModule(publisherIdByAbbr);

  return taxonomy.tooltips.ppc_branding_column_headers.action;
}

export function ActionCellTooltipRenderer({ value }) {
  const publisherIdByAbbr = GetPubAbbr();
  const taxonomy = TaxonomyModule(publisherIdByAbbr);

  if (!value) {
    return null;
  }
  
  const status = value.toUpperCase();

  if (status === "INACTIVE" || status === "DRAFT") {
    return taxonomy.tooltips.ppc_branding_column_inlines.action.start_bidding;
  } else {
    return taxonomy.tooltips.ppc_branding_column_inlines.action.update_bid;
  }
}
  
