import { GetPubAbbr } from "../../../../../helpers/get-pub-abbr";
import { TaxonomyModule } from "../../../../../modules/taxonomy";

export function ClusterPositionRenderer({ value }) {
    if (!value) {
        return null;
    }

    const bids_amount = Number(value.split("@")[2]);
    let currentPos = Number(value.split("@")[0]);
    let estimatedPos = Number(value.split("@")[1]);
    let ppc_bid_active = value.split("@")[3];
    let campaign_active = value.split("@")[4];
    let ppc_paused = value.split("@")[5];
    let top_bid = Number(value.split("@")[6]);
    let status = value.split("@")[7].split("#")[0];

    if (status === "Pending") {
        return (
            <div className="d-flex align-items-center text-secondary">
                Not shown
            </div>
        );
    }

    if (campaign_active === "false") {
        return (
            <div className="d-flex align-items-center text-secondary">
                Not shown
            </div>
        );
    }

    if (currentPos <= bids_amount && currentPos > 0) {
        if (currentPos === 1) {
            return (
                <div className="d-flex align-items-center">
                    <span className="text-success fw-bold">#{currentPos}</span>
                </div>
            );
        } else {
            if (currentPos === estimatedPos) {
                return (
                    <div className="d-flex align-items-center">
                        <span className="text-success fw-bold">#{currentPos}</span>
                    </div>
                );
            } else {
                if (estimatedPos > bids_amount || estimatedPos === 0) {
                    return (
                        <div className="d-flex align-items-center text-secondary">
                           #{currentPos}
                        </div>
                    );
                } else {
                    return (
                        <div className="d-flex align-items-center">
                            <span className="text-secondary">#{currentPos}</span>
                            <i className="ri-arrow-right-line ml-2 mr-2" data-bs-toggle="tooltip" title="Estimated position"></i>
                            <span className="text-success fw-bold">
                                #{estimatedPos}
                            </span>
                        </div>
                    );
                }
            }
        }
    }

    if (currentPos > bids_amount || currentPos === 0) {
        currentPos = "Not shown";
    }

    if (estimatedPos > bids_amount || estimatedPos === 0) {
        estimatedPos = "Not shown";
    }

    if (currentPos !== "Not shown") {
        currentPos = "#" + currentPos;
    }

    if (estimatedPos !== "Not shown") {
        estimatedPos = "#" + estimatedPos;
    }

    if (estimatedPos === "Not shown") {
        return (
            <div className="d-flex align-items-center text-secondary">
                Not shown
            </div>
        );
    }

    if (currentPos !== "Not shown" && estimatedPos == currentPos) {
        return (
            <div className="d-flex align-items-center ml-1">
                <span className="text-success fw-bold">{estimatedPos}</span>
            </div>
        );
    }

    return (
        <div className="d-flex align-items-center">
            <span className="text-secondary">{currentPos}</span>
            <i className="ri-arrow-right-line ml-2 mr-2" data-bs-toggle="tooltip" title="Estimated position"></i>
            <span className="text-success fw-bold">
                {estimatedPos}
            </span>
        </div>
    );
}

export function ClusterPositionColumnHeaderTooltipRenderer() {
    const publisherIdByAbbr = GetPubAbbr();
    const taxonomy = TaxonomyModule(publisherIdByAbbr);

    return taxonomy.tooltips.ppc_cluster_column_headers.position;
}

export function ClusterPositionCellTooltipRenderer({ value }) {
    const publisherIdByAbbr = GetPubAbbr();
    const taxonomy = TaxonomyModule(publisherIdByAbbr);

    if (!value) {
        return null;
    }
    
    const bids_amount = Number(value.split("@")[2]);
    let currentPos = Number(value.split("@")[0]);
    let estimatedPos = Number(value.split("@")[1]);
    let ppc_bid_active = value.split("@")[3];
    let campaign_active = value.split("@")[4];
    let ppc_paused = value.split("@")[5];
    let top_bid = Number(value.split("@")[6]);

    if (isNaN(top_bid)) {
        let tooltip = taxonomy.tooltips.ppc_cluster_column_inlines.position.is_not_shown.replace("{min_bid}", top_bid);
        return tooltip;
        // return `Your ad is not shown on this topic cluster. But you can get to position #1 if you increase your bid.`;
    }

    if (bids_amount < currentPos) {
        let tooltip = taxonomy.tooltips.ppc_cluster_column_inlines.position.is_not_shown.replace("{min_bid}", top_bid);
        return tooltip;
        // return `Your ad is not shown on this topic cluster. Bid minimum ${top_bid} to get to position #1`;
    }

    if (campaign_active === "false") {
        let tooltip = taxonomy.tooltips.ppc_cluster_column_inlines.position.is_not_shown.replace("{min_bid}", top_bid);
        return tooltip;
        // return `Your campaign is not active. That's why your position is not shown.`;
    }

    if (currentPos === 0) {
        let tooltip = taxonomy.tooltips.ppc_cluster_column_inlines.position.is_not_shown.replace("{min_bid}", top_bid);
        return tooltip;
        // return `Your ad is not shown on this topic cluster. But you can get to position #1 if you increase your bid.`;
    }

    if (currentPos <= bids_amount && currentPos > 0) {
        if (currentPos === 1) {
            let tooltip = taxonomy.tooltips.ppc_cluster_column_inlines.position.is_shown.replace("{position}", currentPos);
            return tooltip;
            // return `Your current position is #${currentPos}.`;
        } else {
            if (currentPos === estimatedPos) {
                let tooltip = taxonomy.tooltips.ppc_cluster_column_inlines.position.is_shown.replace("{position}", currentPos);
                return tooltip;
                // return `Your current position is #${currentPos}.`;
            }

            if (estimatedPos > currentPos) {
                let tooltip = taxonomy.tooltips.ppc_cluster_column_inlines.position.is_shown.replace("{position}", currentPos);
                return tooltip;
                // return `Your current position is #${currentPos}. But you can get to position #${estimatedPos} if you increase your bid.`;
            }

            let tooltip = taxonomy.tooltips.ppc_cluster_column_inlines.position.is_shown.replace("{position}", currentPos);
            return tooltip;
            // return `Your current position is #${currentPos}. But you can get to position #${estimatedPos} if you increase your bid.`;
        }
    }
}