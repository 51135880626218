export function normalizeToUTC(date) {
    if (!date) {
        return null;
    }
    
    const offset = date.getTimezoneOffset(); // returns the negative of the UTC offset in minutes
    console.log("offset", offset);
    const newDate = new Date(date.getTime() + (offset * 60 * 1000));
    console.log("newDate", newDate);
    return newDate;
}