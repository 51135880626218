import { CookiesKeyNames, useCookiesContext } from "../../../../../contexts/CookiesContext";
import { GetPubAbbr } from "../../../../../helpers/get-pub-abbr";
import { TaxonomyModule } from "../../../../../modules/taxonomy";

export function NameRenderer({ value }) {
    const {
        cookiesData,
        getPathKey,
    } = useCookiesContext();
    const publisherId = cookiesData[getPathKey()]?.[CookiesKeyNames.publisherId];

    const name = value.split("@")[0];
    const visits = value.split("@")[1];

    if (publisherId != 67 && publisherId != 10) {
        return (
            <div className="table-item-name">
                {name} <span className='badge bg-outline-secondary badg'>{visits} Views</span>
            </div>
        );
    } else {
        return (
            <div className="table-item-name">
                {name}
            </div>
        );
    }
}

export function NameColumnHeaderTooltipRenderer() {
    const publisherIdByAbbr = GetPubAbbr();
    const taxonomy = TaxonomyModule(publisherIdByAbbr);

    let tooltip = taxonomy.tooltips.ppc_specific_column_headers.name;

    return tooltip; // `Current invetory recived ${visits} visits per last 30 days`;
}

export function NameCellTooltipRenderer({ value }) {
    const publisherIdByAbbr = GetPubAbbr();
    const taxonomy = TaxonomyModule(publisherIdByAbbr);
    
    const name = value.split("@")[0];
    const visits = value.split("@")[1];

    let tooltip = taxonomy.tooltips.ppc_specific_column_inlines.name;

    return tooltip; // `Current invetory recived ${visits} visits per last 30 days`;
}
 